<template>
  <div class="lu_novel_h5">
    <div class="top">
      <div class="top_left">
        <div class="icon_div">
          <cjImg class="icon_show" :style="{ height: this.heightLong }" :src="$getImgUrl(imgAddr, appInfo.logoUrl)" />
        </div>
        <div class="title_info">
          <span class="app_name">{{ appInfo.appName }}</span>
          <span class="app_info">{{ appInfo.appDesc }}</span>
        </div>
      </div>
      <div class="top_right">
        <div class="ewm" id="qrcodeh5"></div>
      </div>
    </div>
    <div class="item_1">
      <cjImg style="width: 100%; height: auto; object-fit: contain; position: relative; z-index: 2000" :src="require('@/assets/H5/免费好书尽在葫芦.png')" />
      <div class="bgcolor"></div>
      <span class="font_free">Free books are all there</span>
      <cjImg style="width: 100%; height: auto; object-fit: contain; margin-top: 8vw" :src="require('@/assets/H5/插画3.png')" />
      <div class="button_group">
        <div v-if="type === 'IOS'" class="button" @click="download('IOS')">立即下载</div>
        <div v-if="type === 'Android'" class="button" @click="download('Android')">立即下载</div>
      </div>
    </div>
    <div class="item_2">
      <cjImg style="width: 80%; height: auto; object-fit: contain" :src="require('@/assets/H5/海量正版免费阅读.png')" />
      <div class="font_2">Massive legitimate free reading</div>
      <div class="font_3">葫芦小说与各大内容商合作，同时持续扶持优质的原创内容，涵盖青春、言情、玄幻、校园、仙侠、都市、悬疑等全部主流网文类型，以及大量热剧原著和经典出版物。</div>
      <cjImg class="chatu" :src="require('@/assets/H5/插图1_new.png')" />
    </div>
    <div class="item_3">
      <cjImg style="width: 40%; height: auto; object-fit: contain" :src="require('@/assets/H5/关于我们.png')" />
      <div class="font_2">About us</div>
      <div class="font_3">葫芦小说是于 2019 年全新推出的，面向网文热爱者的免费阅读产品，致力于为读者提供畅快不花钱的极致阅读体验，目前每日活跃用户已超过一千万。</div>
      <cjImg class="chatu" :src="require('@/assets/H5/插图2_new.png')" />
    </div>
    <div class="footer">
      <!--      <span>友情链接：葫芦畅听音乐版</span>-->
      <!--      <div style="margin-top: 5vw">-->
      <!--        <span>北京臻鼎科技有限公司 | 地址 : 北京市海淀区紫金数码园4号楼8层807</span>-->
      <!--        <br />-->
      <!--        <span>违法和不良信息举报：400-140-2108 | 京公网安备 11010802031927号</span>-->
      <!--      </div>-->
      <!--      <div style="margin-top: 5vw">-->
      <!--        <span>京ICP备18016437号-2 | 京网文【2021】3834-1005号</span>-->
      <!--        <br />-->
      <!--        <span>京ICP证 京B2-20181179号</span>-->
      <!--        <br />-->
      <!--        <span>营业执照 | 出版物经营许可证 | 广播电视节目制作经营许可证</span>-->
      <!--      </div>-->
      <span>【如有疑问相关请联系此邮箱：zc975420@gmail.com】</span>
    </div>
  </div>
</template>

<script>
import mobileDetect from 'mobile-detect'
import { addLiquidId, clickReport, getIP, isValidBrowser } from '@/utils/download'
import { queryDownloadInfo } from '@/api/home'
import { getChannelCode } from '@/utils/checkAgent'
import QRCode from 'qrcodejs2'
import cjImg from '@/components/cjImg'
import { base64Encode } from '@/utils/strUtil'
import { lightPackege } from '@/utils/common'

export default {
  name: 'hulu-H5',
  components: { cjImg },
  data() {
    return {
      imgAddr: '',
      appInfo: '',
      heightLong: '',
      type: 'Android',
      aParams: ['', '', '', '']
    }
  },
  created() {
    this.IsPC()
    this.type = this.isAndroid()
    this.aParams = getChannelCode(this.$router.currentRoute.query.a)
    this.heightLong = document.documentElement.clientWidth * (70 / 375) + 2 + 'px'
    this.innitChannelInfo()
  },
  watch: {},
  methods: {
    async innitChannelInfo() {
      const md = new mobileDetect(u)
      let ver = ''
      if (md.os() == 'iOS') {
        //ios系统的处理
        ver = md.versionStr('iPhone')
      } else if (md.os() == 'AndroidOS') {
        //Android系统的处理
        ver = md.versionStr('Android')
      }
      var u = navigator.userAgent
      let deviceInfo = {}
      deviceInfo.isAndroid = !!(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) //android终端
      deviceInfo.isiOS = !!u.match(/(iPhone|iPod|ios|iPad)/i) //ios终端
      deviceInfo.issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
      deviceInfo.isMobile = deviceInfo.isAndroid || deviceInfo.isiOS
      deviceInfo.isValidBrowser = isValidBrowser()
      deviceInfo.packageCode = this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE
      deviceInfo.invitationCode = this.aParams[2] || ''
      deviceInfo.appId = this.aParams[3] || process.env.VUE_APP_APP_ID
      window.deviceInfo = deviceInfo
      let that = this
      let clickId = that.$router.currentRoute.query.clickid
      console.log(that.$router.currentRoute, 'that.$router', window.location.hash.split('?')[1])
      getIP( async function (ip, area) {
        const params = {
          appId: deviceInfo.appId,
          channelCode: deviceInfo.packageCode,
          invitationCode: deviceInfo.invitationCode,
          clickId:clickId,
          deviceInfo: {
            ip: ip || '',
            // localIp: ,
            osType: md.os() == 'iOS' ? 1 : 0,
            osVersion: ver.replace(/\_/g, '.'),
            deviceWidth: window.screen.width,
            deviceHeight: window.screen.height,
            deviceRatio: window.devicePixelRatio
          },
          systemType: 1
        }
        await addLiquidId(params)
        await that.getChannelInfo()
        // sessionReport({ ip })
      })
    },
    getChannelInfo() {
      let params = {"data":{"packageCode":this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE}}
      queryDownloadInfo(params)
        .then((res) => {
          console.log(res)
          this.appInfo = res.data
          this.imgAddr = res.imgAddr
          document.title = res.data.appName
          var link = document.querySelector("link[rel*='icon']")
          window
            .fetchAndDec(this.appInfo.logoUrl)
            .then((res) => {
              link.href = `data:image/jpg;base64,${res}`
            })
            .catch((err) => {})
          let origin = window.location.origin
          let pack = this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE
          let id = this.aParams[3] || process.env.VUE_APP_APP_ID
          let url = origin + '/downLoad?pack='+pack+'&id='+id
          if (window.deviceInfo.invitationCode) {
            url+='&iCode='+window.deviceInfo.invitationCode
          }
          if(this.$router.currentRoute.query.clickid){
            url+=`&clickid=${this.$router.currentRoute.query.clickid}`
          }
          let long = document.documentElement.clientWidth * (70 / 375)
          this.$nextTick(() => {
            new QRCode('qrcodeh5', {
              render: 'canvas',
              width: long,
              height: long,
              text: url
            })
          })
        })
        .catch(() => {})
    },
    async download(type) {
      let link = document.createElement('a')
      link.download = ''
      // let icon_src = ''
      // console.log(this.imgAddr + this.appInfo.logoUrl, 'resresres')

      let appId = process.env.VUE_APP_APP_ID
      let appName = this.appInfo.appName
      // 是否为海外版轻量包，轻量包域名
      let { foreign, prefix } = this.appInfo
      // a参数 base64加密
      let parameter_a = base64Encode('##' + (this.aParams[1]||process.env.VUE_APP_CHANNEL_CODE) + '####' + (this.aParams[3]||appId) + '##')
      // 解析a参数，拼接轻量包类型数据
      let aStr = window.atob(parameter_a) + foreign + '##'
      let endecodeA = window.btoa(aStr)
      let clickid = this.$router.currentRoute.query?.clickid||''
      let a = encodeURIComponent(`${this.aParams[3]||appId}$${appName}$${endecodeA}$${prefix}$${clickid}`)
      let url = `https://jack2030.gitlab.io/pool/${process.env.VUE_APP_ENV}/web.html?a=${a}`
      switch (type) {
        case 'Android':
          link.href = this.appInfo.infoList[1].shopDownloadUrl
          link.download = appName
          clickReport({
            appId: this.aParams[3] || process.env.VUE_APP_APP_ID,
            channelCode: this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE,
            type: 2 //类型 1-在线观看点击量 2-apk点击量 3-轻量包点击量
          }, (res) =>{
            console.log('link.click()',res);
            link.click()
          })
          break
        case 'IOS':
          window
            .fetchAndDec(this.appInfo.logoUrl)
            .then(async (res) => {
              // 16位编码数据（相同应用数值相同，不同应用数值不一样）
              let appIdStr = '33BC3CC7-18CE-4F97-9CF7-A24B608A212E'
              // 用渠道code进行替换
              let channelCode = aStr.split('##')[1].toUpperCase()
              appIdStr = appIdStr.replace(appIdStr.substring(appIdStr.length - channelCode.length), channelCode)
              // console.log('加密后的a参数----', appIdStr);
              // lightPackege(icon_src, url, appName, getUUID(), foreign)
              clickReport({
                appId: this.aParams[3] || process.env.VUE_APP_APP_ID,
                channelCode: this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE,
                type: 3 //类型 1-在线观看点击量 2-apk点击量 3-轻量包点击量
              }, () =>{
                lightPackege(res, url, appName, appIdStr)
              })
            })
            .catch((err) => {})
          break
      }
    },
    IsPC() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      let info = navigator.userAgent
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      let isPhone = /mobile/i.test(info)
      //如果包含“Mobile”（是手机设备）则返回true
      if (isPhone) {
      } else {
        const a = this.$router.currentRoute.query.a
        const clickid = this.$router.currentRoute.query.clickid
        const query = {}
        if (a) {
          query.a = a
        }
        if(clickid){
          query.clickid = clickid
        }
        this.$router.push({
          path: '/pcPage',
          query: query
        })
      }
    },
    isAndroid() {
      let e = navigator.userAgent.toLowerCase()
      let n = /Android/i.test(e)
      let t = /iPhone|iPad|iPod/i.test(e)
      if (n) {
        return 'Android'
      }
      if (t) {
        return 'IOS'
      }
      return 'Android'
    }
  }
}
</script>

<style scoped lang="scss">
.lu_novel_h5 {
  width: 100%;
  height: auto;
  //overflow: auto;
  background-image: url('../../../assets/H5/背景.png');
  background-size: cover;
  background-repeat: no-repeat;
  //background-position: center;
  .top {
    //width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: row;
    padding: 5vw;
    .top_left {
      display: flex;
      align-items: center;
      .icon_div {
        padding: 3px;
        display: flex;
        background-color: #ffffff;
        border: 1px solid #eaeaea;
        border-radius: 6px;
        .icon_show {
          object-fit: contain;
          width: auto;
          //border-radius: 8px;
        }
      }
      .title_info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: start;
        margin-left: 3vw;
        .app_name {
          font-family: PingFangSC-Medium;
          font-size: 5vw;
          color: #363178;
          font-weight: 500;
        }
        .app_info {
          font-family: PingFangSC-Medium;
          font-size: 4vw;
          color: #363178;
          font-weight: 500;
          text-align: left;
        }
      }
    }
    .top_right {
      //width: 20%;
      border: 2px solid #5a75e9;
      padding: 3px;
      border-radius: 6px;
      background-color: #ffffff;
      .ewm {
        //width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e5e5e6;
        //padding: 4px;
        border-radius: 4px;
      }
    }
  }
  .item_1 {
    padding: 10vw;
    position: relative;
    .bgcolor {
      width: 28vw;
      height: 8vw;
      position: absolute;
      top: 14vw;
      left: 10vw;
      background-image: linear-gradient(90deg, #fffddb 0%, #fffbb5 100%);
    }
    .font_free {
      opacity: 0.44;
      font-family: PingFangSC-Regular;
      font-size: 5vw;
      color: #8b85ca;
      font-weight: 400;
    }
    .button_group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .button {
        //width: 40%;
        padding: 3vw 20vw;
        background-image: linear-gradient(123deg, #5a75e9 0%, #73bffa 100%);
        box-shadow: 0px 10px 17px 0px rgba(122, 172, 247, 0.6);
        border-radius: 2vw;
        font-family: PingFangSC-Medium;
        font-size: 3.5vw;
        color: #ffffff;
        letter-spacing: 2px;
        text-align: center;
        font-weight: 500;
      }
    }
  }
  .font_2 {
    opacity: 0.44;
    font-family: PingFangSC-Regular;
    font-size: 4vw;
    color: #8b85ca;
    font-weight: 400;
  }
  .font_3 {
    opacity: 0.6;
    font-family: PingFangSC-Regular;
    font-size: 2.8vw;
    color: #363178;
    font-weight: 400;
    margin-top: 3vw;
  }
  .chatu {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-top: 8vw;
  }
  .item_2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 10vw;
  }
  .item_3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 10vw;
  }
  .footer {
    //text-align: start;
    text-align: center;
    padding: 5vw 4.5vw;
    font-family: PingFangSC-Regular;
    font-size: 2.8vw;
    color: #ffffff;
    line-height: 4vw;
    background-image: linear-gradient(-45deg, #5778ea 0%, #73bffa 100%);
  }
}
</style>
